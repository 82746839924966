import { useSelector } from 'react-redux'
import { useState, useEffect } from 'react';

const Output = (props) => {
    const printJob = useSelector((state) => state.printJob.printJob); 
    const [imagePreview, setImagePreview] = useState('');
    const [submissionText, setSubmissionText] = useState('Generate PDF');

    const generatePDF = () => {
        setSubmissionText('Generating PDF...');
        fetch('/serverside/print_calc/pdf2b64.php?page='+printJob.pageNumber+'&name='+printJob.fileName, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: printJob.pdfFile
        }).then(response => response.json())
        .then(data => {
            setImagePreview(data.result);
            document.getElementById('outputform').submit();
            setSubmissionText('Generate PDF');
        })
    }

    if (props.Output == {}) {
        return (<></>)
    }

    return (
        <div id="outputdiv">
            {printJob.cmykArray != null && (
                <form method="post" id="outputform" action="/serverside/print_calc/pdf.php" target="_blank">
                    <input type="hidden" name="model" value={printJob ? printJob.printerModel : ''} />
                    <input type="hidden" name="profile" value={printJob ? printJob.printerProfile : ''} />
                    <input type="hidden" name="grayscale" value={printJob.useGrayScale} />
                    <input type="hidden" name="note" value={printJob.note} />
                    {printJob.cmykArray[printJob.pageNumber-1] && (
                        <>
                            <input type="hidden" name="cyan" value={printJob.cmykArray[printJob.pageNumber-1][0]} />
                            <input type="hidden" name="magenta" value={printJob.cmykArray[printJob.pageNumber-1][1]} />
                            <input type="hidden" name="yellow" value={printJob.cmykArray[printJob.pageNumber-1][2]} />
                            <input type="hidden" name="black" value={printJob.cmykArray[printJob.pageNumber-1][3]} />
                            <input type="hidden" name="spot" value={printJob.cmykArray[printJob.pageNumber-1][4]*100} />
                        </>
                    )}
                    <input type="hidden" name="image" value={imagePreview} />
                    <input type="hidden" name="costperpage" value={printJob.substrateCalculation !== "CPLF" ? printJob.pageCost : printJob.substrateCost} />
                    <input type="hidden" name="totalcost" value={parseFloat(props.Output['Total']).toFixed(3)} />
                    {(printJob.pageCount && printJob.pageCost) ?
                        <input type="hidden" name="consumablecost" value={parseFloat(props.Output['Consumable Cost']).toFixed(3)}  />
                        : null
                    }
                    <input type="hidden" name="filename" value={printJob.fileName} />
                    <input type="hidden" name="pagesize" value={printJob.pageDimensions} />

                </form>
            )}
            <div id="output" className="collapsible-header grey lighten-2">Print Cost (Reference)</div>
            <div id="outputrow">
                <div className="row">
                    <div className="col s12 m12 l5">  
                        <ul>
                            <li className="active">
                                <div id="summarycpp" style={{marginBottom: "30px"}} className="collapsible-header grey lighten-3 summarycppdiv" tabIndex="0">
                                    <i className="material-icons">chevron_right</i>Cost of Print Per Page (CPP) {printJob.pageCount && printJob.pageCost && (<button onClick={generatePDF} id="generate_pdf" style={{marginTop: "-.75em", marginLeft: "1em"}} className="waves-effect waves-light btn">{submissionText}</button>)}
                                </div>
                                <div className="row">
                                    <div className="col s12 m12 l4" style={ {fontWeight: 'bold'} }  ></div>
                                    <div className="col s12 m12 l4" style={{textAlign: 'right'}}>
                                        <label className="summarycostlabel" style={{fontWeight: 'bold'}}>Price Per Piece</label>
                                    </div>
                                    <div className="col s12 m12 l4" style={{textAlign: 'right'}}>
                                        <label className="summarycostlabel" style={{fontWeight: 'bold'}}>Total Job Price</label>
                                    </div>
                                </div>
                                <div >
                                {
                                Object.keys(props.Output).map((key, index) => {
                                    if (key === 'Consumable Cost') return null;
                                    return (
                                        <div className="row" style={index%2 ? {background: '#eee', minWidth: "550px"} : {minWidth: "550px"}}>
                                            <div className="col s12 m12 l4" style={(key=='Total') ? {fontWeight: 'bold'} : {}}  >{key.replace('ImageDrum', 'Image Drum')}</div>
                                            <div className="col s12 m12 l4" style={{textAlign: 'right'}}>
                                                <label className="summarycostlabel" style={(key=='Total') ? {fontWeight: 'bold'} : {}}>${parseFloat(props.Output[key]).toFixed(6)}</label>
                                            </div>
                                            <div className="col s12 m12 l4" style={{textAlign: 'right'}}>
                                                <label className="summarycostlabel" style={(key=='Total') ? {fontWeight: 'bold'} : {}}>${parseFloat(props.Output[key]*printJob.pageCount).toFixed(2)}</label>
                                            </div>
                                        </div>
                                    )
                                })}
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>    
            </div>
            <div>
                <br/><br/>
                <h3>Terms and Disclaimers</h3>
                <ul className="browser-default"><li>This tool estimates consumable usage for specific IntoPrint models. The tool is not intended to be used as a means of providing a highly accurate accounting of all costs associated with printing a document on a printer. Your actual running costs will vary. Costs displayed by this tool should be judged based on actual experience with the printer and its behavior over a period of consumable
                replacements.</li>
                <li>The displayed cost is based on an estimate of the toner usage and the paper size indicated within the tool. Pay attention to the paper size being used to estimate costs. Toner and other consumable costs will be different if the job is printed using a different media size.</li>
                <li>Toner coverage is displayed as a percentage of the paper size for the estimated job. 10% coverage for a Com-10 envelope is not the same as 10% coverage on a sheet of letter-size paper.</li>
                <li>Estimates assume the printer is running continuously to produce a minimum of 50 copies, allowing engine efficiencies for longer, continuous runs to be factored into the displayed price. Printing fewer copies will likely yield a higher cost per copy, and printing larger numbers of pages may yield lower cost per copy.</li>
                <li>Driver settings and printer menu settings influence consumable usage and may not have been applied to the estimated cost.</li>
                <li>Cost estimates assume the sheet is being fed long-edge-first when possible</li>
                <li>Estimated costs do not include labor, electricity, printer, shipping costs</li></ul>
            </div>
        </div>
    )
}
export default Output;