import ModelSelect from '../components/ModelSelect';
import ProfileConfiguration from '../components/ProfileConfiguration';


import { useSelector, useDispatch } from 'react-redux'
import NavBar from '../components/NavBar';
  
const Configuration = () => {
  const printConfigurations = useSelector((state) => state.printJob.printJob);

 
  return (
      <>
          <NavBar/>
          <h4 id="title" className="center">Configuration</h4>
          <ModelSelect />
          { printConfigurations.printerProfile && (
            <ProfileConfiguration />
          )}
      </>
  )
}

export default Configuration