import React, {useEffect} from 'react';
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux'
import { editPrintJob } from '../slices/printJob';

// these may need to be editable...
const printerMap = {
    'Pro1040': './printer_pics/Pro1040.jpg',
    'Pro1050': './printer_pics/Pro1040.jpg'
}

const ModelSelect = (props) => {
    const auth = useSelector((state) => state.session.auth); 

    const printJob = useSelector((state) => state.printJob.printJob); 
    const [printerModels, setPrinterModels] = React.useState([]);
    const [printerProfiles, setPrinterProfiles] = React.useState([]);
    const [addPrinterModel, setAddPrinterModel] = React.useState(false);
    const [addPrinterProfile, setAddPrinterProfile] = React.useState(false);

    const dispatch = useDispatch();
    const [localSelections, setLocalSelections] = React.useState({printerModel: '', printerProfile: '', calculatorMethod: ''});
    

    useEffect(() => {
        const request = {...auth};
        request.type = 'printerModels';

        fetch('/serverside/print_calc/fetch.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request),
            cache: "no-store",
        }).then((response) => {
            return response.json();
        }).then((data) => {
            setPrinterModels(data);
        });
    }, []);

    const handleAddPrinterProfile = (e) => {
        e.preventDefault();
        const request = {...auth};
        request.type = 'addPrinterProfile';
        request.model_id = localSelections.printerModel;
        request.name = document.getElementById('newPrinterProfile').value;

        fetch('/serverside/print_calc/add.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request),
            cache: "no-store",
        }).then((response) => {
            return response.json();
        }).then((data) => {
            setPrinterProfiles(data);
            const newProfile = data.filter((profile) => profile.name === request.name);
            dispatch(editPrintJob({printerProfile: newProfile[0].id}));
            setLocalSelections({...localSelections, printerProfile: newProfile[0].id});
            setAddPrinterProfile(false);
        });
    }

    const handleAddPrinterModel = (e) => {
        e.preventDefault();
        const request = {...auth};
        request.type = 'addPrinterModel';
        request.name = document.getElementById('newPrinterModel').value;

        fetch('/serverside/print_calc/add.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request),
            cache: "no-store",
        }).then((response) => {
            return response.json();
        }).then((data) => {
            setPrinterModels(data);
            const newModel = data.filter((model) => model.name === request.name);
            dispatch(editPrintJob({printerModel: newModel[0].id}));
            setLocalSelections({...localSelections, printerModel: newModel[0].id});
            setAddPrinterModel(false);
        });
    }

    useEffect(() => {
        if (localSelections.printerModel === 'add') {
            setAddPrinterModel(true);
        } else {
            const request = {...auth};
            request.type = 'printerProfiles';
            request.model_id = localSelections.printerModel;

            fetch('/serverside/print_calc/fetch.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(request),
                cache: "no-store",
            }).then((response) => {
                return response.json();
            }).then((data) => {
                setPrinterProfiles(data);
            });
        }

    }, [localSelections.printerModel])

    const handleSelection = (e) => {
        dispatch(editPrintJob({[e.target.id]: e.target.value}));
        setLocalSelections({...localSelections, [e.target.id]: e.target.value});
    }

    useEffect(() => {
        if (localSelections.printerProfile === 'add') {
            setAddPrinterProfile(true);
        } else {
            const request = {...auth};
            request.profile_id = localSelections.printerProfile;
             // need to fetch json of profile data
            fetch('/serverside/print_calc/fetch_profiles.php', {
                //?profile=' + printJob.printerProfile + '&model=' + printJob.printerModel , {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(request),
                cache: "no-store",
            }).then((response) => {
                return response.json();
            }).then((data) => {
                dispatch(editPrintJob({printerProfileData: data}));
            });
        }
    }, [localSelections.printerProfile]);

    return (
        <>
            <h6 id="modeldropdownlabel" style={{marginBottom: "30px"}}>Select Printer Model and Profile</h6>
            <div className="row">
                <div id="modeldropdown" className="col l2 m3 s12">
                    <select id="printerModel" className="browser-default" value={localSelections.printerModel} onChange={handleSelection}>
                        <option value="">Choose Model</option>
                        {printerModels.map((printerModel) => (
                            <option key={printerModel.id} value={printerModel.id}>{printerModel.name}</option>
                        ))}
                        {auth.role === 'admin' && (
                            <option value="add">Add New</option>
                        )}
                    </select>
                    
                </div>

                <div id="profiledropdown" className="col l2 m3 s12">
                    <select id="printerProfile" className="browser-default" value={localSelections.printerProfile} onChange={handleSelection}>
                        <option value="" >Choose Profile</option>
                        {printerProfiles.map((printerProfile) => (
                            <option key={printerProfile.id} value={printerProfile.id}>{printerProfile.name}</option>
                        ))}
                        {(auth.role === 'admin' && localSelections.printerModel) && (
                            <option value="add">Add New</option>
                        )}
                    </select>
                </div>
                { localSelections.printerModel && localSelections.printerProfile && props.Page === "PrintCalc" && (
                    <>
                        <div id="modediv" className="col l2 m3 s6">
                            <form id="inputrdbtn" className="right" action="#">
                                <label>
                                    <input className="with-gap" id="calculatorMethod" onClick={handleSelection} name="InputMethod" type="radio" value="Upload PDF"/>
                                    <span>Upload PDF</span>
                                </label>
                            </form>
                        </div>
                        <div id="sizerddiv" className="col l2 m3 s6" style={{display: 'none'}}>
                            <form id="sizerdbtn" action="#" className="center">
                                <label>
                                    <input className="with-gap" id="pageUnit" name="pageUnit" type="radio" value="mm" onClick={handleSelection} />
                                    <span>mm</span>
                                </label>
                                <br />
                                <label>
                                    <input className="with-gap" id="pageUnit" name="pageUnit" type="radio" value="inch" onClick={handleSelection} />
                                    <span>inch</span>
                                </label>
                            </form>
                        </div>
                    </>
                )}

                <div className="col l2 m0 s0"></div>
                { localSelections.printerModel !== '' && (
                    <div id="printerimagediv" className="col l2 m4 s8">
                        <img className="responsive-img" src={printerMap[localSelections.printerModel]} id="printerimage" />
                    </div>
                )}
                
            </div>
            <ReactModal 
                isOpen={addPrinterModel}
                contentLabel="Add a New Printer Model"
                >
                <h3>Add a New Printer Model</h3>  
                <label>Name: </label><input type="text" id="newPrinterModel" />
                <button className="waves-effect waves-light btn left" style={{marginRight: "40px"}} onClick={handleAddPrinterModel}>Add Printer Model</button>
                <button className="waves-effect waves-light btn left" onClick={(e) => { e.preventDefault(); setAddPrinterModel(false) }}>Cancel</button>
            </ReactModal>
            <ReactModal 
                isOpen={addPrinterProfile}
                contentLabel="Add a New Printer Profile"
                >
                <h3>Add a New Printer Profile</h3>  
                <label>Name: </label><input type="text" id="newPrinterProfile" />
                <button className="waves-effect waves-light btn left" style={{marginRight: "40px"}} onClick={handleAddPrinterProfile}>Add Printer Profile</button>
                <button className="waves-effect waves-light btn left" onClick={(e) => { e.preventDefault(); setAddPrinterProfile(false) }}>Cancel</button>
            </ReactModal>
        </>
    )
}

export default ModelSelect;