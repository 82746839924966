import { createSlice } from '@reduxjs/toolkit';

export const session = createSlice({
    name: 'session',
    initialState: {
        auth: {}
    },
    reducers: {
        editAuth: (state, action) => {
            const newAuth = {...state.auth};
            for (const key in action.payload) {
                newAuth[key] = action.payload[key];
            }
            state.auth = newAuth
        }
    }
});

export const { editAuth } = session.actions;
export default session.reducer;