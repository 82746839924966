import { createSlice } from '@reduxjs/toolkit';

export const printConfigurationSlice = createSlice({
    name: 'printConfigurations',
    initialState: {
        printConfigurations: []
    },
    reducers: {
        addConfiguration: (state, action) => {
            state.printConfigurations.push(action.payload);
        },
        editConfiguration: (state, action) => {
            const newConfigurations = [...state.printConfigurations];
            const index = newConfigurations.findIndex((configuration) => configuration.id === action.payload.id);
            newConfigurations[index] = action.payload;
            state.printConfigurations = newConfigurations;
        },
    }
});

export const { addConfiguration, editConfiguration } = printConfigurationSlice.actions;
export default printConfigurationSlice.reducer;
