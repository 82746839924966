import { configureStore } from '@reduxjs/toolkit';

import storage from 'redux-persist/lib/storage'
import {combineReducers} from "redux"; 
import { persistReducer } from 'redux-persist'
import {thunk} from 'redux-thunk'


import { printJobSlice } from './slices/printJob';
import { printConfigurationSlice } from './slices/printerConfigurations';
import { session } from './slices/session';

const reducers = combineReducers({
    printJob: printJobSlice.reducer,
    printConfigurations: printConfigurationSlice.reducer,   
    session: session.reducer 
});

const persistConfig = {
    key: 'root',
    storage,
}
  
const persistedReducer = persistReducer(persistConfig, reducers)
  

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk)  
})

export default store;