import React, {useRef, useState, useEffect} from 'react';
import NavBar from '../components/NavBar';

import { useSelector } from 'react-redux'

const Users = () => {
    const auth = useSelector((state) => state.session.auth); 
    const [usersArray, setUsersArray] = useState([]);
    const [message, setMessage] = useState('');
    const [topMessage, setTopMessage] = useState('');
    const formRef = useRef(null);
    const [newUser, setNewUser] = useState({new_username: '', new_password: '', new_role: 'user'});

    const fetchUsers = () => { 
        fetch('/serverside/print_calc/users.php', {
            method: 'POST',
            body: JSON.stringify(auth),
            cache: "no-store",
        })
            .then(response => response.json())
            .then(data => setUsersArray(data));
    }

    useEffect(() => {
       fetchUsers();
    }, []);

    const updateNewUser = (key, value) => {
        setNewUser({...newUser, [key]: value});
    }

    const saveUser = (e) => {
        e.preventDefault();

        const request = {...newUser, ...auth};
        request.action = 'add';
        fetch('/serverside/print_calc/users.php', {
            method: 'POST',
            body: JSON.stringify(request),
            cache: "no-store",
        })
        .then(response => response.json())
        .then(data => {
            if (!data.success) {
                setMessage(data.message);
            } else {
                setMessage('User added');
                setNewUser({new_username: '', new_password: '', new_role: 'user'});
                fetchUsers();
                formRef.current.reset();
            }

            setTimeout(() => {
                setMessage('');       
            }, 1500)
        });
    }

    const deleteUser = (id) => {
        return () => {
            const request = {deletionId: id, ...auth, action: 'delete'};
            fetch('/serverside/print_calc/users.php', {
                method: 'POST',
                body: JSON.stringify(request),
                cache: "no-store",
            })
            .then(response => response.json())
            .then(data => {
                if (!data.success) {
                    setTopMessage(data.message);
                } else {
                    setTopMessage('User deleted');
                    setUsersArray(usersArray.filter((user) => user.id !== id));
                }

                setTimeout(() => {
                    setTopMessage('');       
                }, 1500)
            });
        }
    }

    return (
        <>
            <NavBar/>
            <h4 id="title" className="center">Manage Users</h4>
            
            <center>
                <em><strong>{topMessage}</strong></em>
                <div style={{maxWidth: "500px", margin: 'auto'}}>
                    <table style={{maxWidth: "500px", magin: 'auto'}}>
                        <thead><tr><th>Username</th><th>Role</th><th>Action</th></tr></thead>
                        <tbody>
                            {usersArray.map((user) => (
                                <tr key={user.username}><td>{user.username}</td><td>{user.role}</td><td><button onClick={deleteUser(user.id)}>Delete</button></td></tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <h4>Add User:</h4>
                <em><strong>{message}</strong></em>
                <form style={{maxWidth: "500px"}} ref={formRef} id="adduserform">
                    <input type="text" id="new_username" value={newUser.username} onChange={(e) => updateNewUser(e.target.id, e.target.value)} placeholder="Username" />
                    <input type="password" id="new_password" value={newUser.password} onChange={(e) => updateNewUser(e.target.id, e.target.value)}placeholder="Password" />
                    <select id="new_role" value={newUser.role} onChange={(e) => updateNewUser(e.target.id, e.target.value)} className="browser-default">
                        <option value="user">User</option>
                        <option value="admin">Admin</option>
                    </select>
                    <button onClick={saveUser} type="submit" id="adduserbutton" className="waves-effect waves-light btn">Add User</button>
                </form>
            </center>
            
        </>
    )
}

export default Users;