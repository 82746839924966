import { useDispatch, useSelector } from 'react-redux'
import {useState, useEffect} from 'react';
import { editPrintJob } from '../../slices/printJob';

const SectionSettings = (props) => {
    const printJob = useSelector((state) => state.printJob.printJob); 
    const dispatch = useDispatch();

    const [pageCost, setPageCost] = useState("$0.00");
    const [timeoutTracker, setTimeoutTracker] = useState(false);
    const [pageWidth, setPageWidth] = useState('');
    const [pageLength, setPageLength] = useState('');
    const [pageCount, setPageCount] = useState(1);
    const [substrateCalculation, setSubstrateCalculation] = useState("Page Cost");
    const [substrateToggleButtonText, setSubstrateToggleButtonText] = useState("Switch to Media Cost");
    const [noteValue, setNoteValue] = useState('');

    useEffect(() => {
        setPageWidth(printJob.pageWidth);
        setPageLength(printJob.pageLength);
    }, [printJob]);

    const updateNote = (e) => {
        e.preventDefault();
        setNoteValue(e.target.value);
        dispatch(editPrintJob({note: e.target.value}));
    }
    
    const toggleSubstrateCost = (e) => {
        e.preventDefault();
        if (substrateCalculation == "Page Cost") {
            setSubstrateCalculation("Cost Per Linear Foot");
            setSubstrateToggleButtonText("Switch to Page Cost");
            dispatch(editPrintJob({substrateCalculation: "CPLF"}))
        } else {
            setSubstrateCalculation("Page Cost");
            setSubstrateToggleButtonText("Switch to Media Cost");
            dispatch(editPrintJob({substrateCalculation: "CPP"}));
        }
    }

    // takes inch values in and converts to mm if mm was chosen
    const insertProperUnits = (value) => {
        if (printJob.pageUnit == "in" || printJob.pageUnit == "inch") {
            return value;
        } else {
            value = Number(value) * 25.4;
            return value.toFixed(2).toString();
        }
    
    }

    useEffect(() => {
        if (timeoutTracker) {
            clearTimeout(timeoutTracker);
            setTimeoutTracker(false);
        }
        setTimeoutTracker(setTimeout(function() {
            let numeral = parseFloat(pageCost.replace('$', ''));
            numeral = '$' + numeral.toFixed(2);
            setPageCost(numeral);
        }, 2000));
    }, [pageCost]);

    useEffect(() => {
        dispatch(editPrintJob({pageArea: pageWidth * pageLength}));
        if (pageWidth > pageLength) {
            dispatch(editPrintJob({longestDimension: pageWidth}));
        } else {
            dispatch(editPrintJob({longestDimension: pageLength}));
        }
        dispatch(editPrintJob({pageDimensions: pageWidth + ' x ' + pageLength}));
    }, [pageWidth, pageLength]);


    const setPageSize = () => {
        switch (document.getElementById('pagesizeselect').value) {
            case '1':
                setPageWidth(insertProperUnits('8.5'));
                setPageLength(insertProperUnits('11'));
                dispatch(editPrintJob({pageWidth: '8.5', pageLength: '11'}));
                break;
            case '2':
                setPageWidth(insertProperUnits('4'));
                setPageLength(insertProperUnits('6'));
                dispatch(editPrintJob({pageWidth: '4', pageLength: '6'}));
                break;
            case '3':
                setPageWidth('');
                setPageLength('');
                break;
            default:
                setPageWidth('');
                setPageLength('');
                break;
        }
    }

    const handleWidthChange = (value) => {
        if (document.getElementById('pagesizeselect').value != 3) {
            setPageSize();
        } else {
            dispatch(editPrintJob({pageLength: '11'}));

            setPageWidth(value);
        }
    }
    const handleLengthChange = (value) => {
        if (document.getElementById('pagesizeselect').value != 3) {
            setPageSize();
        } else {
            dispatch(editPrintJob({pageWidth: value}));

            setPageLength(value);
        }
    }
    const handleSelectChange = () => {
        setPageSize();
    }
    const handlePageCountChange = (value) => {
        setPageCount(value);
        dispatch(editPrintJob({pageCount: value}));
    }
    const handlePageCostChange = (value) => {
        setPageCost(value);
        dispatch(editPrintJob({pageCost: value}));
    }

    return (
        <div className="row" >
                  <div className="col s12 m12 l4">
                      <fieldset id="mediadetails">
                          <legend id="legend">Media Details</legend>
                          <div id="pagesize" style={{display: "none"}} className="input-field col s12 m12 l12">
                              <div className="select-wrapper">
                              <select onChange={handleSelectChange} className="validate" id="pagesizeselect" name="PageSize" tabIndex="-1">
                                <option value="">Page Size</option>
                                <option value="1">Letter</option>
                                <option value="2">4 X 6</option>
                                <option value="3">Custom Size</option>
                              </select></div>
                              <input id="SelectedPageSize" name="SelectedPageSize" type="hidden" value="" />
                          </div>                    
                          <div id="pagewidth" className="input-field col s12 m6 l6">
                              <input className="validate" data-val="true" data-val-number="The field PageWidth must be a number." id="page_width" name="PageWidth" type="text" value={printJob.pageWidth} onChange={(e) => {handleWidthChange(e.target.value);}}  />
                              <label id="pagewidthlabel" htmlFor="page_width">Width</label>

                          </div>
                          
                          
                          <div id="pageheight" className="input-field col s12 m6 l6">
                              <input className="validate" data-val="true" data-val-number="The field PageLength must be a number." id="page_length" name="PageLength" type="text" value={printJob.pageLength} onChange={(e) => {handleLengthChange(e.target.value);}} />
                              <label id="pagelengthlabel" htmlFor="page_length">Length</label>
                          </div>
                      </fieldset>

                  </div>
                  <div className="col s12 m12 l4">
                      <fieldset id="pagedetailsfieldset">
                          <legend id="legend">Page Details </legend>

                          <div className="input-field col s12 m12 l12">
                              <input className="validate" data-val="true" data-val-number="The field PageGap must be a number." id="page_gap" name="PageGap" type="text" onChange={(e) => {handlePageCountChange(e.target.value); }} value={pageCount} />
                              <label id="pagegaplabel" htmlFor="page_gap">Page Count</label>
                          </div>
                          
                          <div id="pagecost" className="input-field col s12 m12 l12">
                              <input className="validate" id="page_cost" name="PageCostWithCurrency" type="text"  onChange={(e) => {handlePageCostChange(e.target.value);}} value={pageCost} />
                              <label id="pagecostlabel" htmlFor="page_cost" className="active">{substrateCalculation}</label>
                              <input data-val="true" data-val-number="The field PageCost must be a number." id="PageCost" name="PageCost" type="hidden" />
                              <button onClick={toggleSubstrateCost}>{substrateToggleButtonText}</button><br />
                              <br/>
                          </div>
                          <label id="Note" htmlFor="note" className="active">Note</label>
                            <textarea  style={{borderRadius: '12px', padding: '2px 5px'}} id="note" name="Note" cols="20" rows="2" value={noteValue} onChange={(e) => updateNote(e)}></textarea>
                          
                      </fieldset>
                  </div>
              </div>
    )
}

export default SectionSettings;