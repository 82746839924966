import React, { useState } from 'react';
import NavBar from '../components/NavBar';
import { useSelector, useDispatch } from 'react-redux'
import { editAuth } from '../slices/session';
import { useNavigate } from 'react-router-dom';


const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [user, setUserObject] = useState({username: '', password: ''})
    const [errorMessage, setErrorMessage] = useState('');
    const [createAccount, setCreateAccount] = useState(false);

    const handleUserChange = (e) => {
        setUserObject({...user, [e.target.id]: e.target.value});
    }

    const handleCreateAccount = (e) => {
        e.preventDefault();
        if (user.password !== user.confirm_password) {
            setErrorMessage('Passwords do not match');
            return;
        }
        const request = {};
        request.new_username = user.username
        request.new_password = user.password
        request.action = 'add';
        
        fetch('/serverside/print_calc/users.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request)
        }).then((response) => {
            return response.json();
        }).then((data) => {
            if (data.success) {
                handleLogin(e);
            } else {
                setErrorMessage(data.message);
            }
        });
    }

    const handleLogin = (e) => {
        e.preventDefault();
        fetch('/serverside/print_calc/authenticate.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(user)
        }).then((response) => {
            return response.json();
        }).then((data) => {
            if (data.success) {
                dispatch(editAuth(data));
                navigate('/');
            } else {
                setErrorMessage(data.message);
            }
        });
    }

    return (
        <div>
            <NavBar/>
            <h2>Login</h2>
            <h3>{errorMessage}</h3>
            {!createAccount && (<><form style={{maxWidth: '400px', display: 'block', margin: 'auto', marginBottom: '40px'}}>
                <label>
                    Username:
                    <input type="text" id="username" value={user.username} onChange={handleUserChange}/>
                </label>
                <label>
                    Password:
                    <input type="password" id="password" value={user.password} onChange={handleUserChange} />
                </label>
                <button type="submit" onClick={handleLogin}>Submit</button><br/>
            </form><br/>
            <center><button style={{float: 'none'}}  id="calculatebtn" onClick={(e) => setCreateAccount(true)} className="waves-effect waves-light btn">Click Here to Create an Account</button></center>
            </>
            )}
            {createAccount && (<><form style={{maxWidth: '400px', display: 'block', margin: 'auto', marginBottom: '40px'}}>
                <label>
                    Username:
                    <input type="text" id="username" value={user.username} onChange={handleUserChange}/>
                </label>
                <label>
                    Password:
                    <input type="password" id="password" value={user.password} onChange={handleUserChange} />
                </label>
                <label>
                    Confirm Password:
                    <input type="password" id="confirm_password" value={user.confirm_password} onChange={handleUserChange} />
                </label>
                <button type="submit" onClick={handleCreateAccount}>Create Account</button>
            </form><br/>
            <center><button id="calculatebtn" onClick={(e) => setCreateAccount(false)} style={{float: 'none'}} className="waves-effect waves-light btn">Click Here to Log In</button></center>
            </>)}
        </div>
    )
}

export default Login;