import React from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { editPrintJob } from '../../slices/printJob';

const ColorCoverages = (props) => {
    const [buttonLabel, setButtonLabel] = React.useState("Switch to Grayscale");

    const printJob = useSelector((state) => state.printJob.printJob); 
    const dispatch = useDispatch();

    const displayPercent = (value) => {
        return (Math.round(value*100)/100).toFixed(2) + "%";
    }

    const toggleColor = () => {
        let newUseGrayScale = false;

        if (printJob.useGrayScale === undefined) {
            newUseGrayScale = true;
        } else {
            newUseGrayScale = !printJob.useGrayScale;
        }

        dispatch(editPrintJob({useGrayScale: newUseGrayScale}))

        if (newUseGrayScale) {
            setButtonLabel("Switch to Color");
        } else {
            setButtonLabel("Switch to Grayscale");
        }
    }

    return (
        <fieldset id="coveragediv">
            <legend id="legend">Color Coverage</legend>
            <div id="cyancovdiv" className="input-field col s6 m6 l2">
                <i id="icon-cyan" className="material-icons prefix">lens </i>
                <input className="validate valid" id="cyan_coverage" name="CyanCoverage" type="text" value={(printJob.useGrayScale) ? '0.00%' : displayPercent(printJob.cmykArray[printJob.pageNumber-1][0])} readOnly={true} />
                <label className="required active" id="cyanlabel" htmlFor="cyan_coverage">Cyan</label>
            </div>
            <div id="magentacovdiv" className="input-field col s6 m6 l2">
                <i id="icon-magenta" className="material-icons prefix">lens </i>
                <input className="validate valid" id="magenta_coverage" name="MagentaCoverage" type="text" value={(printJob.useGrayScale) ? '0.00%' : displayPercent(printJob.cmykArray[printJob.pageNumber-1][1])} readOnly={true}/>
                <label className="required active" id="magentalabel" htmlFor="magenta_coverage">Magenta </label>
            </div>
            <div id="yellowcovdiv" className="input-field col s6 m6 l2">
                <i id="icon-yellow" className="material-icons prefix">lens </i>
                <input className="validate valid" id="yellow_coverage" name="YellowCoverage" type="text" value={(printJob.useGrayScale) ? '0.00%' : displayPercent(printJob.cmykArray[printJob.pageNumber-1][2])} readOnly={true}/>
                <label className="required active" id="yellowlabel" htmlFor="yellow_coverage">Yellow</label>
            </div>
            <div className="input-field col s6 m6 l2">
                <i id="icon-black" className="material-icons prefix">lens </i>
                <input className="validate valid" id="black_coverage" name="BlackCoverage" type="text" value={displayPercent(printJob.cmykArray[printJob.pageNumber-1][3])} readOnly={true}/>
                <label className="required active" id="blacklabel" htmlFor="black_coverage">Black </label>

            </div>
            <div id="spotcovdiv" className="input-field col s6 m6 l2">
                <i id="icon-spot" className="material-icons prefix">panorama_fish_eye </i>
                <input className="validate valid" id="spot_coverage" name="SpotCoverage" type="text" value={Math.round(printJob.cmykArray[printJob.pageNumber-1][4]*10000)/100 + '%'} readOnly={true}/>
                <label className="required active" id="spotlabel" htmlFor="spot_coverage">SpotColor_White</label>
            </div>
            <div id="spotcovdiv" className="">
                <button id="calculatebtn" onClick={toggleColor} style={{width: "240px", marginBottom: "30px"}} className="waves-effect waves-light btn left">{buttonLabel}</button>                                
            </div>   
            <br/><br/>
        </fieldset>
    )
}

export default ColorCoverages;