import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom"


import PrintCalculator from './pages/PrintCalculator';
import Configuration from './pages/Configuration';
import Login from './pages/Login';
import Users from './pages/Users';
import { editAuth } from './slices/session';

function App() {
  const auth = useSelector((state) => state.session.auth); 

  if (auth.userId !== undefined && auth.userId !== '') {
    return (
      <>
        <Router>
          <Routes>
            <Route path="/" element={<PrintCalculator />} />
            <Route path="/Configuration" element={<Configuration /> } />
            <Route path="/users" element={<Users /> } />
            <Route path="/login" element={<Login /> } />
          </Routes>
        </Router>
      </>
    );
  } else {
    return (
      <>
        <Router>
            <Routes>
              <Route path="/" element={<PrintCalculator />} />
              <Route path="/login" element={<Login /> } />
            </Routes>
        </Router>
      </>
    )
  }
}

export default App;
