import React, { useEffect } from 'react';
import { useSelector } from 'react-redux'

const ProfileConfiguration = (props) => {
    const auth = useSelector((state) => state.session.auth);
    const printJob = useSelector((state) => state.printJob.printJob); 
    const [profileData, setProfileData] = React.useState(printJob.printerProfileData);
    const [message, setMessage] = React.useState('');

    useEffect(() => {
        setProfileData(printJob.printerProfileData);
    }, [printJob.printerProfileData]);

    function changeHandler(property, value) {
        if (auth.role !== 'admin' && ( property.indexOf('Enable') !== -1 || property.indexOf('Yield') !== -1)) {
            return;
        }
        let newProfileData = {...profileData};
        newProfileData[property] = value;
        setProfileData(newProfileData);
    }

    function fetchProfileData(property) {
        if (!profileData[property]) {
            return '';
        } else {
            return profileData[property];
        }
       
    }
    function handleDelete(type) {
        if (window.confirm("Are you sure you want to delete this profile?")) {
            setProfileData({});
            const request = {...auth, model_id: printJob.printerModel, profile_id: printJob.printerProfile, action: 'del'+type};

            fetch('/serverside/print_calc/save_profile.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(request)
            }).then((response) => {
                return response.json();
            }).then((data) => {
                setMessage("Saved succesfully");

                setTimeout(() => {
                    setMessage('');
                }, 1500);
            });

            alert("Profile deleted");
        }

    }
    function handleSave() {
        const request = {...auth, data: profileData, model_id: printJob.printerModel, profile_id: printJob.printerProfile};

        fetch('/serverside/print_calc/save_profile.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request)
        }).then((response) => {
            return response.json();
        }).then((data) => {
            setMessage("Saved succesfully");

            setTimeout(() => {
                setMessage('');
            }, 1500);
        });
    }

    return (
        <>
            {auth.role === 'admin' && (
                <div style={{clear: 'both', marginBottom: '50px'}}>
                    <button id="deletebtn" onClick={() => handleDelete('profile')} type="submit" name="Deletebtn" className="waves-effect waves-light btn left" style={{marginBottom: '2em', float: 'none !important'}}>Delete Profile</button>
                    <button id="deletebtn" onClick={() => handleDelete('model')} type="submit" name="Deletebtn" className="waves-effect waves-light btn left" style={{marginBottom: '2em', float: 'none !important'}}>Delete Model</button>

                </div>
            )}
            <div id="configsection" className="section">
                <ul className=" expandable">
                    <li className="active">
                        <div id="currencydiv" className="-header grey lighten-3" tabIndex="0"><i className="material-icons">chevron_right</i>General</div>
                        <div className="">
                            <div className="row">
                                <div id="currencydrpdown" className="col l2 m6 s10">
                                    <label className="active">Currency</label>
                                    <div className="select-wrapper">
                                       <select className="" value={fetchProfileData('CurrencyId')} onChange={(e) => changeHandler('CurrencyId', e.target.value)} id="CurrencyId" name="CurrencyId" tabIndex="-1">
                                                <option>Choose Currency</option>
                                                <option value="USD">USD ($)</option>
                                                <option value="Yen">Yen (¥)</option>
                                                <option value="Euro">Euro (€)</option>
                                                <option value="Pound">Pound (£)</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    {auth.role === 'admin' && (<li className="active">
                        <div id="costdiv" className="-header grey lighten-3" tabIndex="0"><i className="material-icons">chevron_right</i>Variables</div>
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <strong>Max Print Width</strong>
                                    </td>
                                    <td>
                                        <strong>Count Breakover Length</strong>
                                    </td>                           
                                    <td>
                                        <strong>Coverage Base Square Inches</strong>
                                    </td>
                                    <td>
                                        <strong>Coverage Base Percent</strong>
                                    </td>
                                    <td>
                                        <strong>Max Toner Yield</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input data-val="true" data-val-number="The field MaxPrintWidth must be a number." data-val-required="The MaxPrintWidth field is required." id="MaxPrintWidth" name="MaxPrintWidth" value={fetchProfileData('MaxPrintWidth')} onChange={(e) => changeHandler('MaxPrintWidth', e.target.value)} />
                                    </td>
                                    <td>
                                        <input data-val="true" data-val-number="The field CountBreakoverLength must be a number." data-val-required="The CountBreakoverLength field is required." id="CountBreakoverLength" name="CountBreakoverLength" value={fetchProfileData('CountBreakoverLength')} onChange={(e) => changeHandler('CountBreakoverLength', e.target.value)} />
                                    </td>
                                    <td>
                                        <input data-val="true" data-val-number="The field CoverageBaseSquareInches must be a number." data-val-required="The CoverageBaseSquareInches field is required." id="CoverageBaseSquareInches" name="CoverageBaseSquareInches" value={fetchProfileData('CoverageBaseSquareInches')} onChange={(e) => changeHandler('CoverageBaseSquareInches', e.target.value)} />
                                    </td>
                                    <td>
                                        <input data-val="true" data-val-number="The field CoverageBasePercent must be a number." data-val-required="The CoverageBasePercent field is required." id="CoverageBasePercent" name="CoverageBasePercent" value={fetchProfileData('CoverageBasePercent')} onChange={(e) => changeHandler('CoverageBasePercent', e.target.value)} />
                                    </td>
                                    <td>
                                        <input data-val="true" data-val-number="The field MaxTonerYield must be a number." data-val-required="The MaxTonerYield field is required." id="MaxTonerYield" name="MaxTonerYield" value={fetchProfileData('MaxTonerYield')} onChange={(e) => changeHandler('MaxTonerYield', e.target.value)} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </li>)}
                    <li className="active">
                        <div id="cosumablecostdiv" className="-header grey lighten-3" tabIndex="0"><i className="material-icons">chevron_right</i>Consumables Cost</div>
                        <div className="">
                            <div className="row">
                                <div className="col s10 m8 l4 consumabletable">
                                    <fieldset id="consumablefieldset" className="consumablefieldset">
                                        <legend id="legend">Toner </legend>
                                        <div id="table" className="table-editable">
                                            <table className="table" id="tableToner">
                                                <tbody><tr>
                                                    <th id="name"></th>
                                                    <th id="cost">Cost</th>
                                                    <th id="yeild">Yield</th>
                                                    <th id="enable">Enable</th>
                                                </tr>
                                                {
                                                    ["Toner.Black", "Toner.Yellow", "Toner.Magenta", "Toner.Cyan", "Toner.Spot"].map((toner) => {
                                                        return (
                                                            <tr key={toner}>
                                                                <td className="grey lighten-4"><i id={"table" + toner.split('.')[1].toLowerCase() + "icon"} className="material-icons prefix ispaces">lens </i>{toner.split('.')[1]}</td>
                                                                <td id="tdTonerBlackCost"  className="contentEditabletext">
                                                                    <input data-val="true" data-val-number="The field BlackCost must be a number." data-val-required="The BlackCost field is required." id="Toner_BlackCost" name="Toner.BlackCost" value={fetchProfileData(toner + "Cost")} onChange={(e) => changeHandler(toner + "Cost", e.target.value)} />
                                                                </td>
                                                                <td id="tdTonerBlackYield"  className="contentEditabletext">
                                                                    <input data-val="true" data-val-number="The field BlackYield must be a number." data-val-required="The BlackYield field is required." id="Toner_BlackYield" name="Toner.BlackYield" value={fetchProfileData(toner + "Yield")} onChange={(e) => changeHandler(toner + "Yield", e.target.value)}/>
                                                                </td>
                                                                <td >
                                                                    <input value="1" data-val-required="The BlackEnable field is required." id="Toner_BlackEnable" name="Toner.BlackEnable" type="checkbox" checked={fetchProfileData(toner+"Enable")} onChange={(e) => changeHandler(toner+"Enable", e.target.checked)}  />
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody></table>

                                        </div>                                   
                                    </fieldset>
                                </div>
                                <div className="col s10 m8 l4 consumabletable">
                                    <fieldset id="consumablefieldset1" className="consumablefieldset">
                                        <legend id="legend">Image Drum </legend>
                                        <div id="table" className="table-editable">
                                            <table className="table">
                                                <tbody><tr>
                                                    <th id="name"></th>
                                                    <th id="cost">Cost</th>
                                                    <th id="yeild">Yield</th>
                                                    <th id="enable">Enable</th>
                                                </tr>
                                                {
                                                    ["ImageDrum.Black", "ImageDrum.Yellow", "ImageDrum.Magenta", "ImageDrum.Cyan", "ImageDrum.Spot"].map((imageDrum) => {
                                                        return (
                                                            <tr key={imageDrum}>
                                                                <td className="grey lighten-4"><i id={"table" + imageDrum.split('.')[1].toLowerCase() + "icon"} className="material-icons prefix ispaces">lens </i>{imageDrum.split('.')[1]}</td>
                                                                <td id="tdImageDrumBlackCost"  className="contentEditabletext">
                                                                    <input data-val="true" data-val-number="The field BlackCost must be a number." data-val-required="The BlackCost field is required." id="ImageDrum_BlackCost" name="ImageDrum.BlackCost" value={fetchProfileData(imageDrum + "Cost")} onChange={(e) => changeHandler(imageDrum + "Cost", e.target.value)}  />
                                                                </td>
                                                                <td id="tdImageDrumBlackYield"  className="contentEditabletext" title="30000">
                                                                    <input data-val="true" data-val-number="The field BlackYield must be a number." data-val-required="The BlackYield field is required." id="ImageDrum_BlackYield" name="ImageDrum.BlackYield"  value={fetchProfileData(imageDrum + "Yield")} onChange={(e) => changeHandler(imageDrum + "Yield", e.target.value)} />
                                                                </td>
                                                                <td >
                                                                    <input data-val="true" data-val-required="The BlackEnable field is required." id="ImageDrum_BlackEnable" name="ImageDrum.BlackEnable" type="checkbox" checked={fetchProfileData(imageDrum+"Enable")} onChange={(e) => changeHandler(imageDrum+"Enable", e.target.checked)}   />
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                }
                                            </tbody></table>
                                        </div>
                                    </fieldset>
                                </div>
                                <div className="col s10 m8 l4 consumabletable">
                                    <fieldset id="beltfuserfieldset" >
                                        <legend id="legend">Fuser, Belt, Waste Toner Box </legend>
                                        <div id="table" className="table-editable">
                                            <table id="beltfuserboxtable" className="table">
                                                <tbody><tr>
                                                    <th id="name"></th>
                                                    <th id="cost">Cost</th>
                                                    <th id="yeild">Yield</th>
                                                    <th id="enable">Enable</th>
                                                </tr>
                                                {
                                                    ["Fuser", "Belt", "WasteToner"].map((item) => {
                                                        return (
                                                            <tr key={item}>
                                                                <td className="grey lighten-4">{item}</td>
                                                                <td id={"tdOthers" + item + "Cost"}  className="contentEditabletext">
                                                                    <input data-val="true" data-val-number={"The field " + item + "Cost must be a number."} data-val-required={"The " + item + "Cost field is required."} id={"Others_" + item + "Cost"} name={"Others." + item + "Cost"} value={fetchProfileData("Others." + item + "Cost")} onChange={(e) => changeHandler("Others." + item + "Cost", e.target.value)} />
                                                                </td>
                                                                <td id={"tdOthers" + item + "Yield"}  className="contentEditabletext">
                                                                    <input data-val="true" data-val-number={"The field " + item + "Yield must be a number."} data-val-required={"The " + item + "Yield field is required."} id={"Others_" + item + "Yield"} name={"Others." + item + "Yield"} value={fetchProfileData("Others." + item + "Yield")} onChange={(e) => changeHandler("Others." + item + "Yield", e.target.value)} />
                                                                </td>
                                                                <td >
                                                                    <input data-val="true" data-val-required={"The " + item + "Enable field is required."} id={"Others_" + item + "Enable"} name={"Others." + item + "Enable"} type="checkbox" checked={fetchProfileData("Others." + item + "Enable")} onChange={(e) => changeHandler("Others." + item + "Enable", e.target.checked)} />
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                }
                                            </tbody></table>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
                <div className="row">
                    <button id="savebtn" onClick={handleSave} type="submit" name="Savebtn" className="waves-effect waves-light btn left" disabled="">Save</button>
                    <br/><em>{message}</em>
                </div>
            </div>
        </>
    )
}
export default ProfileConfiguration;