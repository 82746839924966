import React from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'

import { editAuth } from '../slices/session';

const NavBar = () => {
    const auth = useSelector((state) => state.session.auth); 
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const logout = (e) => {
        e.preventDefault();
        dispatch(editAuth({username: '', role: '', userId: ''}));
        navigate('/');
    }

    return (
         <nav id="navbar" style={{position: 'relative', 'top': '-11px'}}>
            <div className="nav-wrapper grey darken-3">
                <ul className="right hide-on-med-and-down nav">
                    {auth.role === 'admin' && (
                        <li className="active"><Link to={"/users"}><i className="material-icons left">add_circle</i>Manage Users</Link></li>
                    )}
                    <li className="active"><Link to={"/"}><i className="material-icons left">add_circle</i>Cost Calculator</Link></li>

                    {auth.username && (
                        <>
                            <li className="active"><Link onClick={logout} to={"/logout"}><i className="material-icons left">add_circle</i>Logout</Link></li>
                            <li id="configurationli"><Link to={"/Configuration"}><i className="material-icons left">settings</i>Configuration</Link></li>
                        </>
                    )}
                    {(!auth.username) && (
                        <li className="active"><Link to={"/login"}><i className="material-icons left">add_circle</i>Login</Link></li>
                    )}
                </ul>
            </div>
        </nav>
    )
}

export default NavBar;